import { postReq, uploadReq } from '../utils/request'
import { Response } from '../types/api'
import { PaymentData } from '../types/data'

export default {
	sendForm: (data: object): Response<{ data?: { paymentData?: PaymentData }}> => postReq('/api/v1/form', data),
	newsletterSubscribe: (data: object) => postReq('/api/v1/newsletter/subscribe', data),
	uploadFormAttachment: (data: object, header: object) => uploadReq('/api/v1/upload/form-attachment', data, header),
	sendWhereToPlantForm: (data: object) => postReq('/api/v1/form/where-to-plant', data),
}
