import Axios from 'axios'
import getConfig from 'next/config';
const { serverRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || window.location.origin

const axios = Axios.create({
	baseURL: apiUrl
})

type Headers = { [name: string]: string | boolean }

type Config = {
	headers: Headers,
	params?: object
	baseURL?: string
	data?: object
	showNotification: boolean
}

type DownloadConfig = Config & {
	responseType: 'blob'
}

function buildHeaders(items?: Headers): Headers {
	const headers = {
		Accept: 'application/json',
		'Access-Control-Allow-Credentials': true,
		'Cache-Control': 'no-cache, no-store',
		'Pragma': 'no-cache',
		'Accept-Language': 'sk',
		'Client-type': 'BRATISLAVA_TREES'
	}

	return {
		...headers,
		...items || {}
	}
}

function buildConfig(params?: object, accept?: string | null, headers?: Headers, data?: object, showNotification: boolean = true): Config {
	const config: Config = {
		headers: buildHeaders(headers),
		showNotification: showNotification
	}

	if (accept) {
		config.headers.Accept = accept
	}

	if (params) {
		config.params = params
	}

	if (data) {
		config.data = data
	}

	return config
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { string } accept header
 * @param { Object } headers
 * @return Promise response
 * Performs get request to url and returns callback with result
 */
export function getReq(path: string, params?: object, accept?: string, headers?: Headers) {
	return axios.get(path, buildConfig(params, accept, headers))
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { Object } data
 * @param { Object } headers
 * @param { boolean } showNotification
 * @return Promise response
 * Performs post request to url and returns callback with result
 */
export function postReq(path: string, data?: object, params?: object, headers?: Headers, showNotification: boolean = true) {
	return axios.post(path, data || {}, buildConfig(params, null, headers, undefined, showNotification))
}

export function uploadReq(path: string, file?: any, headers?: any) {
	const config = {
		headers: buildHeaders(headers)
	}
	const form = new FormData()
	form.append('file', file)

	return axios.post(path, form, config)
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { Object } data body
 * @param { Object } headers
 * @return Promise response
 * @param { boolean } showNotification
 * Performs put request to url and returns callback with result
 */
export function putReq(path: string, data?: object, params?: object, headers?: Headers, showNotification: boolean = true) {
	return axios.put(path, data || {}, buildConfig(params, null, headers, undefined, showNotification))
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { Object } data body
 * @param { Object } headers
 * @param { boolean } showNotification
 * @return Promise response
 * Performs put request to url and returns callback with result
 */
export function patchReq(path: string, data?: object, params?: object, headers?: Headers, showNotification: boolean = true) {
	return axios.patch(path, data || {}, buildConfig(params, null, headers, undefined, showNotification))
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { Object } headers
 * @param { Object } data
 * @param { boolean } showNotification
 * @return Promise response
 * Performs delete request to url and returns callback with result
 */
export function deleteReq(path: string, params: object = {}, headers?: Headers, data?: object, showNotification: boolean = true) {
	return axios.delete(path, buildConfig(params, null, headers, data, showNotification))
}

/**
 * @param { string } path endpoint
 * @param { Object } params object
 * @param { Object } headers
 * @return Promise response
 * Performs delete request to url and returns callback with result
 */
export function downloadReq(path: string, params: object = {}, headers?: Headers) {
	const config: DownloadConfig = {
		headers: buildHeaders(headers),
		responseType: 'blob',
		showNotification: true
	}

	if (params) {
		config.params = params
	}

	return axios.get(path, config)
}
